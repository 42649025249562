.cardbuy{
    display: grid;
    grid-template-rows: auto auto auto ;
    grid-gap: 10px;
}
.tarjetas {
    width: 330px;
    height: 200px;
    background-color: #fcfcfc;
    border: 0.5px solid #bdbdbd;
    display: grid;
    grid-template-columns: 160px 160px;
    grid-gap: 10px;
    border-radius: 10px;
    margin: 10px;
  }
  .user__img {
    width: 100%;
    height: 200px;
    border-radius: 14px;
    display: flex;
    align-items: center;
  }
  .user_photo {
    border-radius: 9px 15px 15px 9px;
    width: 100%;
    height: 98%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .user__txt {
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-gap: 5px;
  }
  .user_test {
    font-size: large;
  }
  .texto__titulo {
    font-family: "Roboto";
    text-align: center;
    text-transform: uppercase;
    word-break:break-all;
  }
  .txt__price {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
    display: flex;
    justify-content: center;
  }
  .btn__curos {
    width: 88px;
    height: 30px;
    background: #1a3d82;
    color: #ffff;
    border-radius: 6px;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
  }
  .caja__txt__p {
    background-color: #838383;
    border-radius: 5px;
    font-family: "Roboto";
    font-size: 10px;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    margin: 0 1px;
    height: 23px;
    display: flex;
    align-items: center;
  }
  .boton_oculto {
   position: absolute;
    width: 160px;
    height: 30px;
    opacity: 0;
    background-color: #1a3d82;
    cursor: pointer;
    border-radius: 0 0 10px 10px;
    top: 134px;
    display: flex;
    justify-content: center;
  }
  /**/
  .contedor__btn__oferta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .btn__oferta {
    width: 51px;
    height: 30px;
    background-color: #ff0000;
    color: white;
    font-size: 12px;
    font-style: normal;
    line-height: 12px;
    border-radius: 8px;
    text-align: center;
    font-family: "Roboto";
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 7px;
  }
  /**/
  /*hover css*/
  .boton_oculto:hover {
    position: absolute;
    top: 150px;
    opacity: 0.8;
    transition: all 0.9s ease-out;
    height: 60px;
  }
  .btn__curos:hover {
    cursor: pointer;
  }
  .tarjetas:hover {
    cursor: pointer;
  }
  .tabla____button__design__icon {
    color: #ffff;
    font-size: 21px;
    margin: 0 15px;
  }
  /*contenedor etiquetas carousel*/
  .container_carousel_btn {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    flex-wrap: wrap;
  }
  .container_carousel_btn2 {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    justify-content: center;
  }
  .calendario{
    border: solid 1px #E1E4E8;
    border-radius: 5px;
    width: 120px;
    height: 39px;
    text-align: center;
    outline: none;
  }
  @media (max-width: 768px) {
    .tarjetas {
      width: auto;
      height: auto;
      grid-template-columns: 120px 100px;
      grid-gap: 5px;
      margin: 10px;
    }
    .caja__txt__p {
      font-size: 8px;
      height: 21px;
    }
    .btn__curos {
      width: 62px;
      font-size: 8px;
    }
    .btn__oferta {
      width: 35px;
      font-size: 8px;
    }
    .container_carousel_btn{
      display: flex;
      flex: wrap;
    }
  }
  