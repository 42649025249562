.btn__general{
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #1A3D82;
    color:white;
    border: 1px solid #E1E4E8;
    display:flex;
    justify-content: center;
    align-items: center;
}
.btn__out{
    width: 70px;
    height: 40px;
    border-radius: 8px;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
}
.txt__out{
    font-family: "Roboto";
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #0D0C22;
    line-height: 16px;
    
}