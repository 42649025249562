.estilo_btn_carousel {
  border-radius: 100px;
  box-sizing: border-box;
  background: #ffffff;
  max-height: 50px;
  margin: 0 10px;
  font-weight: normal;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contenedor_carousel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.contenedor__barratodo{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.contenedor__barratodop{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.bloque {
  width: 1400px;
  height: 200px;
  margin: 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btn__next__carousel {
  width: 60px;
  height: 60px;
  background-color: #cccccc;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #e1e4e8;
  text-align: center;
  font-weight: 400;
  color: #666666;
}
.contenedor__Search {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
.text__search {
  text-align: center;
  font-size: 18px;
}
.contenedor__search__center{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}
.container__card{
    width: 100%;
    height: 100%;
}
.contenedor__btn__barra{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

@media(max-width: 768px){
  .contenedor__barratodo{
    display: inline-block;
  }
  .contenedor__btn__barra{
    visibility: hidden; 
    position:absolute;
    display: none;
  }
  .search{
    display:flex;
    justify-content: center;
    align-items: center;
    width:100vw;
  }
  .estilo_btn_carousel{
    font-size: 9px;
  }
  .hamburguer{
    background-color: white;
    width: 50px;
    height: 50px;
  }
}
