.fondo-azul-folder{
  filter: invert(14%) sepia(21%) saturate(5058%) hue-rotate(201deg) brightness(89%) contrast(91%);
}

.azul-nimbus{
  background-color: #1A3D82;
}
.azul-folder{
  filter: invert(17%) sepia(97%) saturate(1408%) hue-rotate(203deg) brightness(89%) contrast(90%);
}

.darker-azul-folder{
  filter: invert(17%) sepia(97%) saturate(1408%) hue-rotate(203deg) brightness(50%) contrast(90%);
}

.ladrillo-nimbus{
    background-color : #d06b64;
  }
.ladrillo-folder{
  filter: invert(29%) sepia(72%) saturate(3638%) hue-rotate(347deg) brightness(88%) contrast(96%);
}

.cardenal-nimbus{
  background-color: #f83a22;
}

.cardenal-folder{
  filter: invert(46%) sepia(93%) saturate(5766%) hue-rotate(350deg) brightness(101%) contrast(95%);
}

.fresas-nimbus{
  background-color: #fa573c;
}

.fresas-folder{
  filter: invert(37%) sepia(78%) saturate(1359%) hue-rotate(338deg) brightness(103%) contrast(96%);
}

.naranja-nimbus{
  background-color: #ff7537;
}

.naranja-folder{
  filter: invert(61%) sepia(27%) saturate(5756%) hue-rotate(337deg) brightness(103%) contrast(101%);
}

.taxi-nimbus{
  background-color: #ffad46;
}

.taxi-folder{
  filter: invert(83%) sepia(38%) saturate(1572%) hue-rotate(323deg) brightness(100%) contrast(103%);
}

.menta-nimbus{
  background-color: #42d692;
}

.menta-folder{
  filter: invert(63%) sepia(48%) saturate(499%) hue-rotate(100deg) brightness(101%) contrast(95%);
}

.helecho-nimbus{
  background-color: #16a765;
}

.helecho-folder{
  filter: invert(51%) sepia(19%) saturate(1794%) hue-rotate(101deg) brightness(98%) contrast(87%);
}

.esparrago-nimbus{
  background-color: #7bd148;
}

.esparrago-folder{
  filter: invert(78%) sepia(86%) saturate(393%) hue-rotate(40deg) brightness(89%) contrast(82%);
}

.limon-nimbus{
  background-color: #b3dc6c;
}

.limon-folder{
  filter: invert(82%) sepia(26%) saturate(692%) hue-rotate(35deg) brightness(94%) contrast(98%);
}

.arena-nimbus{
  background-color: #fbe983;
}

.arena-folder{
  filter: invert(82%) sepia(32%) saturate(519%) hue-rotate(5deg) brightness(106%) contrast(97%);
}

.macarrones-nimbus{
  background-color: #fad165;
}

.macarrones-folder{
  filter: invert(87%) sepia(40%) saturate(727%) hue-rotate(331deg) brightness(103%) contrast(96%);
}

.espuma-nimbus{
  background-color: #92e1c0;
}

.espuma-folder{
  filter: invert(90%) sepia(16%) saturate(770%) hue-rotate(92deg) brightness(93%) contrast(89%);
}

.piscina-nimbus{
  background-color: #9fe1e7;
}

.piscina-folder{
  filter: invert(88%) sepia(76%) saturate(413%) hue-rotate(157deg) brightness(94%) contrast(92%);
}

.vaquero-nimbus{
  background-color: #9fc6e7;
}

.vaquero-folder{
  filter: invert(81%) sepia(13%) saturate(973%) hue-rotate(177deg) brightness(94%) contrast(92%);
}

.cielo-nimbus{
  background-color: #4986e7;
}

.cielo-folder{
  filter: invert(55%) sepia(34%) saturate(5423%) hue-rotate(198deg) brightness(94%) contrast(92%);
}

.terciopelo-nimbus{
  background-color: #9a9cff;
}

.terciopelo-folder{
  filter: invert(62%) sepia(64%) saturate(1795%) hue-rotate(203deg) brightness(102%) contrast(101%);
}

.dinosaurio-nimbus{
  background-color: #b99aff;
}

.dinosaurio-folder{
  filter: invert(75%) sepia(83%) saturate(2862%) hue-rotate(207deg) brightness(100%) contrast(102%);
}

.raton-nimbus{
  background-color: #5f6368;
}

.raton-folder{
  filter: invert(37%) sepia(3%) saturate(782%) hue-rotate(173deg) brightness(98%) contrast(88%);
}

.montana-nimbus{
  background-color: #cabdbf;
}

.montana-folder{
  filter: invert(97%) sepia(1%) saturate(6744%) hue-rotate(288deg) brightness(85%) contrast(85%);
}

.lombriz-nimbus{
  background-color: #cca6ac;
}

.lombriz-folder{
  filter: invert(83%) sepia(8%) saturate(809%) hue-rotate(302deg) brightness(86%) contrast(85%);
}

.chicle-nimbus{
  background-color: #f691b2;
}

.chicle-folder{
  filter: invert(92%) sepia(21%) saturate(5100%) hue-rotate(289deg) brightness(98%) contrast(97%);
}

.lluvia-nimbus{
  background-color: #cd74e6;
}

.lluvia-folder{
  filter: invert(72%) sepia(89%) saturate(4620%) hue-rotate(240deg) brightness(106%) contrast(80%);
}

.berenjena-nimbus{
  background-color: #a47ae2;
}

.berenjena-folder{
  filter: invert(81%) sepia(50%) saturate(6625%) hue-rotate(219deg) brightness(91%) contrast(93%);
}

.selected{
  border: 3px solid gray;
  padding: 3px;
}

.gray-svg{
  filter: invert(72%) sepia(8%) saturate(423%) hue-rotate(179deg) brightness(92%) contrast(77%);
  opacity: 30%;
}

.white-svg{
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(123deg) brightness(103%) contrast(104%);
}