.tarjetas__fav{
    width: 330px;
    height: 211px;
    background-color: #fcfcfc;
    border: 0.5px solid #bdbdbd;
    display: grid;
    grid-template-columns: 160px 160px;
    grid-template-rows: 80% 20%;
    grid-gap: 10px;
    border-radius: 10px;
    margin: 10px;
    position: relative;
}
.user_photo2 {
    border-radius: 9px 15px 15px 9px;
    width: 100%;
    height: 104%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 8px;
  }