@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.inputNumber::-webkit-outer-spin-button, .inputNumber::-webkit-inner-spin-button{
  -webkit-appearance: none;
    margin: 0;
}

.table{
  display: flex;
  justify-content: center;
}
.table table{
  border-collapse: collapse;
  border: 1px solid;
}
.table table tbody tr{
  
  border: 1px solid;
} 
.table table tbody tr td{
  border: 1px solid #dcdcdc;
}

.bg-fill{
  background-size:100%;
}

.btn-nimbus{
  border:1px solid #dcdcdc;
  border-radius:8px;
  background:#1a3d82;
  color:white;
  padding:.5rem 1.25rem;
}

.btn-light{
  border:1px solid #dcdcdc;
  border-radius:8px;
  background:#f5f5f5;
  color:#606060;
  padding:.5rem 1.25rem;
}

.btn-lightBlue{
  border:1px solid #dcdcdc;
  border-radius:8px;
  background:#e5e7eb;
  color:#1a3d82;
  padding:.5rem 1.25rem;
}

.btn-nimbus:hover{
  transition:all .3s ease-in-out;
  filter:brightness(1.1)
}

input[type='date']::-webkit-calendar-picker-indicator {
  background-image: url('./assets/img/datePicker.svg');
}

.visor{
  overflow: auto;
  word-break: break-word;
}

.visor ul {
  list-style-type: none;
  font-weight: 400;
  font-size: 18px;
  color: #3A404C;
}

/* Deshabilitado por el momento */
/* .visor ul li::before,
.editor-styles ul li::before,
.editor-wrapper .ck-content ul li::before{
  content:'';
  background-image: url('./assets/icons/bulletList.svg');
  background-repeat: no-repeat;
  padding-left: 1.25rem;
  background-size: 0.7rem 1rem;
  background-position: 0rem 5px;
} */

.visor ul,
.visor ol,
.editor-styles ul,
.editor-styles ol,
.editor-wrapper .ck-content ul,
.editor-wrapper .ck-content ol{
  padding-left:1.25rem !important;
}

.visor ol{
  font-weight: 400;
  font-size: 18px;
  color: #3A404C;
}

/* Remueve la opción para asignar width a una tabla */
.ck.ck-labeled-field-view.ck-table-form__dimensions-row__width,
.ck-table-form__dimension-operator{
  display: none !important;
}

/* scrollbar */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #606060; 
    background: #b4bed3;
}

::-webkit-scrollbar-thumb {
    background: #fff; 
    border-radius: 999px;
    border:1px solid #dcdcdc;
}

::-webkit-scrollbar-thumb:hover {
    background: #fff; 
}

/* tooltips */
.delayed-tooltip .__react_component_tooltip{
  /* transition-delay: 2s !important; */
}

/* Estilos globales para ckeditor, bloques dentro de constructor y visor */

.image-style-block-align-left{
  margin-left: 0 !important;
  margin-right: auto !important;
}

.image-style-block-align-right {
  margin-left: auto !important;
  margin-right: 0 !important;
}

.image_resized img {
  width: 100%;
}

.editor-styles{
  padding:.75rem;
  border-radius:.5rem;
  width: 100%;
  overflow: auto;
}

.ck-content{
  background:inherit !important ;
  min-height: 25rem;
}

.evidencias-editor .ck-content{
  min-height: 12rem !important;
}

.ck.ck-toolbar__items * :first-letter{
  text-transform: uppercase;
}

.ck.ck-toolbar__items *{
  text-transform: lowercase;
}

.editor-styles h1,
.editor-wrapper h1,
.visor h1{
  font-size: 2.5rem;
}

.editor-styles h2,
.editor-wrapper h2,
.visor h2{
  font-size: 2rem;
}

.editor-styles h1,
.editor-wrapper h1,
.visor h1{
  font-size: 2.5rem;
}

  .editor-styles p,
  .editor-wrapper p,
  .visor p{
    font-size: 1rem;
    line-height: 1.5rem;
    min-height: 24px;
    margin-bottom:.5rem !important;
  }

.editor-styles h1,
.editor-styles h2,
.editor-styles p,
.editor-styles ul,
.editor-styles ol,
.editor-wrapper h1,
.editor-wrapper h2,
.editor-wrapper p,
.editor-wrapper ul,
.editor-wrapper ol,
.visor h1,
.visor h2,
.visor p,
.visor ul,
.visor ol{
  text-align:initial;
  margin-bottom:1rem;
}

.editor-styles h1,
.editor-wrapper h1,
.visor h1,
.editor-styles h2,
.editor-wrapper h2,
.visor h2,
.editor-styles h3,
.editor-wrapper h3,
.visor h3{
  color: #1A3D82;
}

.editor-styles li,
.editor-wrapper li,
.visor li{
  margin-bottom:5px;
}

figure.table{
  margin: 0 auto;
}

@media(max-width:768px){
  .editor-styles h1,
  .editor-wrapper h1,
  .visor h1{
    font-size: 1.75rem;
  }

  .editor-styles h2,
  .editor-wrapper h2,
  .visor h2{
    font-size: 1.5rem;
  }

  .editor-styles h1,
  .editor-wrapper h1,
  .visor h1,
  .editor-styles h2,
  .editor-wrapper h2,
  .visor h2,
  .editor-styles h3,
  .editor-wrapper h3,
  .visor h3{
    font-size: 1.25rem;
  }
}

/* Estilos bloque de código */

.editor-styles pre,
.editor-wrapper pre,
.visor pre{
  background: hsla(0,0%,78%,.3);
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  color: #353535;
  direction: ltr;
  font-style: normal;
  min-width: 200px;
  padding: 1em;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
}
/* Estilos bloque de quotation */

.editor-styles blockquote,
.editor-wrapper blockquote,
.visor blockquote{  
    border-left: 5px solid #ccc;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;

}

/* Estilos de tabla globales ckeditor */

.editor-styles table,
.editor-wrapper table,
.visor table{
  width:100%;
  text-align:left;
  color: #697d99;
  border:1px solid #dcdcdc;
  font-weight: normal;
  border-collapse: separate;	
  border-spacing:0px;
  margin-bottom: 1rem;
}

.table{
  width: 100% !important;
}

.editor-styles table th,
.editor-wrapper table th,
.visor table th{
  text-align:left;
  background:#1a3d82 !important;
  color:white;
  padding:10px;
}

.editor-styles p,
.editor-wrapper p,
.visor p{
  line-height: inherit;
}

.editor-styles table tr td,
.visor table tr td{
  padding:16px;
  border: 1px solid #dcdcdc;
}

.visor table{
  margin:1.5rem 0;
}

[data-cke-tooltip-text="Insertar imagen"]:before,
[data-cke-tooltip-text="Insertar contenido multimedia"]:before{
  content:'Nota: Si utilizas una url no se verá si no estás conectado a internet.';
  display: block;
  position: absolute;
  background-color: rgb(53, 53, 53);
  border-radius: 4px;
  color: white;
  padding: 5px;
  opacity: 0;
  transition:all 0s;
  top: -100000rem;
}

[data-cke-tooltip-text="Insertar imagen"]:hover:before,
[data-cke-tooltip-text="Insertar contenido multimedia"]:hover:before{
  top:60px;
  opacity: 1;
  transition-delay:.7s;
}


/* Estilos link ckeditor */

.editor-styles a,
.editor-wrapper a,
.visor a{
  color:#007bff;
  cursor:pointer;
}

.editor-styles a,
.editor-wrapper a{
  pointer-events: none;
}

.editor-styles a:hover,
.editor-wrapper a:hover,
.visor a:hover{
  text-decoration:underline;
}

/* Estilos imagen ckeditor */

  .image_resized{
    display:inline-flex;
  }

  .editor-styles .image,
  .visor .image{
    display: table;
    margin: 0.9em auto;
    text-align: center;
  }

.editor-styles .image-style-side,
.visor .image-style-side{
  float: right;
  max-width: 50%;
}

.editor-styles .image-style-align-left,
.visor .image-style-align-left{
  float: left;
  margin-right: 15px;
  margin-bottom:10px;
  margin-top:0;
}

.editor-styles .image-style-align-right,
.visor .image-style-align-right{
  float: right;
  margin-left: 15px;
  margin-bottom: 10px;
  margin-top:0;
}

.editor-wrapper figcaption,
.editor-styles figcaption,
.visor figcaption{
  font-size: .75rem;
  background: transparent !important;
  background-color: transparent !important;
  padding:.5rem
}

  .editor-styles .image,
  .visor .image{
    display: table;
    text-align: center;
  }

.editor-styles ul li ,
.editor-styles ol li ,
.editor-wrapper ul li,
.editor-wrapper ol li,
.visor ul li,
.visor ol li{
  text-align: initial;
  font-size:16px;
  color: #606060;
  margin-left: 1rem;
}

.editor-styles ul,
.editor-styles ol,
.editor-wrapper ul,
.editor-wrapper ol,
.visor ul,
.visor ol{
  list-style: revert;
}

/* Toolbar de ckeditor  */

.ck.ck-editor__top.ck-reset_all,
.ck.ck-sticky-panel .ck-sticky-panel__content_sticky,
.sticky-toolbar-element{
  position: sticky !important;
  top: -17px !important;
  width: 100% !important;
  z-index: +999;
}

.ck.ck-editor__top.ck-reset_all,
.ck.ck-sticky-panel .ck-sticky-panel__content_sticky{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.ck .ck-sticky-panel__placeholder{
  display:none !important;
}

/* tabla general */

.general-table{
  width: 100%;
  text-align:left;
}

.general-table tbody tr{
  border: 1px solid #dcdcdc;
  border-width: 0 1px 1px 1px;
}

.general-table th,
.general-table td{
  padding:.75rem;
}

.general-table th:first-of-type {
  border-top-left-radius: 10px;
}
.general-table th:last-of-type {
  border-top-right-radius: 10px;
}
.general-table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
}
.general-table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}

/* provisional ============> remplazar por tailwind */

body{
  color: #606060 !important;
  font-family: 'Roboto', sans-serif;
}

.roulette-container > div {
  overflow: hidden;
  max-width: 700px;
  max-height: 700px;
  width: 100%;
  height:100%;
  aspect-ratio:1;
}

/* pasar a tailwind */

.memory-game {
width: 100%;
max-width: 800px;
height: auto;
margin: auto;
display: flex;
flex-wrap: wrap;
perspective: 1000px;
display: grid;
grid-template-columns: repeat(5, minmax(0, 1fr));
}

.memory-card {
aspect-ratio:.8;
margin: 5px;
position: relative;
transform: scale(1);
transform-style: preserve-3d;
transition: transform 0.5s;
}

@media(max-width:768px){
.memory-game{
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}
}

.memory-card:active {
transform: scale(0.97);
transition: transform 0.2s;
}

.memory-card.flip {
transform: rotateY(180deg);
}

.front-face,
.back-face {
width: 100%;
height: 100%;
padding: 20px;
object-fit: contain;
position: absolute;
background: #1c7ccc;
backface-visibility: hidden;
border-radius: 0.75rem;
}

.front-face {
padding: 0;
object-fit: cover;
border: 1px solid #dcdcdc;
background:#fff;
}

.front-face {
transform: rotateY(180deg);
}

/*  */

.cardsContainer{
position:relative;
min-height: 600px;
background: radial-gradient(#acd3f3, #80c6ff);
border-radius: .5rem;
}

.CardsGame {
position: absolute;
width: 600px;
height: 400px;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}

/* .card-container {
position: absolute;
max-height: 18rem;
} */

.card {
width: 12rem;
height: 18rem;
position: relative;
}

.front-card,
.back-card {
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
border-radius: 1rem;
padding: .75rem;
box-sizing: border-box;
}

.front-card {
background-image: url("https://i.ibb.co/ccbXYm3/logo-Nimbus.png");
background-color: #1c7ccc;
transition: all 0.3s ease-in-out;
border: 8px solid #fff;
box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
  rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.back-card {
background: #fff;
transition: all 0.3s ease-in-out;
transition-delay: 0.3s;
border: 2px solid #dcdcdc;
text-align: center;
box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
  rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
color: #627d92;
}

.flip {
transform: rotateY(90deg);
}

.reverse-flip {
transform: rotateY(-90deg);
}

.move {
transition: all 0.15s ease;
transform: translateX(200%);
}

.move-back {
transition: all 0.15s ease;
transform: translateX(0);
}

@media(max-width:768px){

.CardsGame{
  width: 100% !important;
}

.cardsContainer{
  min-height: 450px;
}

.move{
  transform: translateX(110%) !important;
}
}

/*  */

.cards-btn {
padding: 0.5rem 0;
border-radius: 0.5rem;
background: #1c7ccc;
color: white;
cursor: pointer;
margin-top: 2.5rem;
display: flex;
justify-content: center;
width: 100%;
border: none;
}

.a {
position: absolute;
top: 0.5rem;
left: 0.5rem;
}

.b {
position: absolute;
bottom: 0.5rem;
right: 0.5rem;
}

.c {
position: absolute;
top: 0.5rem;
right: 0.5rem;
/*  */
transform: rotate(45deg);
}

.d {
position: absolute;
bottom: 0.5rem;
left: 0.5rem;
/*  */
transform: scale(-1) rotate(45deg);
}

.btn-cont {
position: absolute;
white-space: nowrap;
}

.swiper-button-prev {
background-image: url('./assets/icons/arrowLeft.svg');
background-repeat: no-repeat;
}

.swiper-button-next{
background-image: url('./assets/icons/arrowRight.svg');
background-repeat: no-repeat;
}

.swiper-button-next::after{
display: none;
}

.swiper-button-prev::after{
display: none;
}

@media(max-width:468px){
.swiper-button-prev {
  background-image: url('./assets/icons/arrowLeft.svg');
  width: 1.125rem !important;
  transform: translateX(-10px);
  background-repeat: no-repeat;
  background-size: contain;
}

.swiper-button-next{
  background-image: url('./assets/icons/arrowRight.svg');
  width: 1.125rem !important;
  transform: translateX(10px);
  background-size: contain;
  background-repeat: no-repeat;
}
}

/* Estilos para la librería multicarousel */

.react-multiple-carousel__arrow{
  background:#53646b63 !important;
}

.react-multi-carousel-dot-list button{
width: 20px !important;
height: 20px !important;
margin-right: 8px !important;
}

@media(max-width:767px){
  .react-multi-carousel-dot-list{
    display: none !important;
  }
}

.react-multi-carousel-dot button{
  border-color: #acafb0 !important;
  background-color: #acafb0 !important;
}

.react-multi-carousel-dot--active button{
  background-color: #22bafb !important;
  border-color:#22bafb !important;
}

.flex-carousel .react-multiple-carousel__arrow{
  border-radius: 8px !important;
  min-height: 80px;
  background-color:#0000005c !important;
}

.flex-carousel .react-multiple-carousel__arrow--right{
  right: 10px;
}

.flex-carousel .react-multiple-carousel__arrow--left{
  left: 10px;
}

/*Arrastrar */

.outside-grid{
grid-column: 1 / -1;
}

/* Sopa de letras temporal */
#root-container .site-header {
background-color: rgba(0, 0, 0, .85);
-webkit-backdrop-filter: saturate(180%) blur(20px);
backdrop-filter: saturate(180%) blur(20px);
}
#root-container .site-header a {
color: #999;
transition: ease-in-out color .15s;
}
#root-container .site-header a:hover {
color: #fff;
text-decoration: none;
}

#root-container .cell {
flex: 1;
background-color: #efefef;
text-align: center;
margin-left: 0px;
margin-top: 0px;
font-family: 'Roboto', sans-serif;
box-sizing: border-box;
transition: all 0.1s;
user-select: none;
color: #555;
border:1px solid white;
color:#697d99;
background:white;
border-radius: .5rem;
transition:all .15s ease-in-out;
display: flex;
align-items: center;
justify-content:center;
aspect-ratio: 1;
}

#root-container .cell:last-of-type {
border-right-width: 0px;
}
#root-container .row:last-of-type > .cell {
border-bottom-width: 0px;
}

#root-container .cell:hover {
border-radius: .5rem;
border: 1px solid #dcdcdc;
cursor: pointer;
}

#root-container .cell.hilighted {
background-color: #18bafb99;
color: #fcfcfc;
border-radius:.75rem;
border: 1px solid;
}

#root-container .row {
display: flex;
width: auto;
}

#root-container .grid {
width: auto;
margin: auto;
border:1px solid #9dd2ea;
padding: 10px;
background-color: white;
border-radius:.5rem !important;
box-sizing: border-box;
}

#root-cotainer .container {
display: flex;
margin: auto;
width: 70%;
}

#root-container ul{
  padding: 0;
  margin: 0;
  text-align:center;
  list-style:none;
}
/* Crucigrama */

.crossword .grid{
  height:fit-content;
}

.clues,
.clue{
color: #627d92 !important;
}

.clues{
padding-left:2rem !important;
}

.clue{
margin-bottom:1rem;
}

.clues .direction .header{
display:none;
}

[aria-label="clue-0-across"]:before {
content:"Horizontal:";
display:block;
font-size: 1.15rem;
margin-bottom: 1rem;
margin-top: 1rem;
font-weight: bold;
}

[aria-label="clue-0-down"]:before {
content:"Vertical:";
display:block;
font-size: 1.15rem;
margin-bottom:.75rem;
margin-top: 1rem;
font-weight: bold;
}

/* Herramienta de dibujo */

.ptro-holder-wrapper{
z-index:+99;
}

/* Permite que el bloque imagen pueda ser redimensionado con react-rdn */

.bloque-imagen .react-draggable,
.bloque-video .react-draggable{
position: relative !important;
transform: translate(0,0) !important;
max-width: 100% !important;
border: 1px dashed #60a5fa;
}

.mircophone-container {
display: flex;
justify-content: center;
align-items: center;
padding: 1rem 0;
}
.microphone-icon-container {
width: 50px;
height: 50px;
border-radius: 50%;
background: #18bafb;
padding: 20px;
margin-right: 20px;
position: relative;
cursor: pointer;
aspect-ratio: 1;
}
.microphone-icon-container.listening::before {
content: "";
width: 70px;
height: 70px;
/* background-color: #00000081; */
background-color: #4a98e399;
position: absolute;
top: 50%;
left: 50%;
transform:translate(-50%, -50%) scale(1.4);
border-radius: 50%;
animation: listening infinite 1.5s;
}
@keyframes listening{
0%{
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
100%{
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.4);
}
}
.microphone-icon {
width: 100%;
height: 100%;
}
.microphone-status {
  margin-right: 8px;
}
.btn {
border: none;
padding: 15px 30px;
margin-right: 10px;
outline: none;
cursor: pointer;
font-size: 17px;
border-radius: 25px;
box-shadow: 0px 0px 10px 5px #ffffff1a;
}
.microphone-result-container {
text-align: center;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;

}
.microphone-result-text {
margin-bottom: 30px;
/* width: 70vw; */
overflow-y: auto;
}
.microphone-reset {
border: 1px solid #fff;
background: none;
color: white;
width: fit-content;
}

.custom-transition{
transition:all .3s ease-in-out;
}

.audio-animation{
position:relative;
}

.audio-animation::before{
content: "";
width: 190px;
height: 190px;
/* background-color: #00000081; */
background-color: #4a98e399;
position: absolute;
top: 50%;
left: 50%;
transform:translate(-50%, -50%) scale(1.4);
border-radius: 50%;
animation: listening infinite 1.5s;
}

.loader {
width: 48px;
height: 48px;
border: 5px solid #FFF;
border-bottom-color: #1A3D82;
border-radius: 50%;
display: inline-block;
box-sizing: border-box;
animation: rotation 0.8s linear infinite;
}

@keyframes rotation {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
} 

/* paint */
.ptro-icon-triangle:before,
.ptro-icon-triangleType2:before{
  border-bottom-color: black !important;
}
.ptro-color-active-control .ptro-icon-triangle:before,
.ptro-color-active-control .ptro-icon-triangleType2:before{
  border-bottom-color: white !important;
}

[data-id="fontName"] {
  width:150px !important;
  font-size:.8rem;
}


/*.places-container {
position: absolute;
top: 10px;
left: 50%;
transform: translateX(-50%);
z-index: 10;
width: 300px;
}*/


.map-container {
width: 800px;
height: 600px;
}
  
.combobox-input {
width: 200px;
padding: 0.5rem;
}

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 


  /* paint */

  .ptro-icon-triangle:before{
    border-bottom-color: black !important;
  }

  .ptro-color-active-control .ptro-icon-triangle:before{
    border-bottom-color: white !important;
  }
.map-container {
  width: 800px;
  height: 600px;
}

.map-container_report {
  width: 100% - 30px;
  height: 800px;
  min-height: 200px;
  min-width: 150px;
  }
.combobox-input {
width: 200px;
padding: 0.5rem;
}

.margin-space{
  margin-left: 2rem;
}

.constructor-editor .ck-reset_all{
  padding-right:139px !important;
}

.search-option-container:hover {
  padding: 10px;
  margin-right: 10px;
  border-color: #18bafb;
  border-width: 1px;
  cursor: pointer;
  font-size: 20px;
  }

.hide-number-arrow::-webkit-inner-spin-button,
.hide-number-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-number-arrow {
  appearance: none;
  -moz-appearance: textfield;
}
/* General dropdown */
.custom-dropdown-container {
  position: absolute;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  border: 1px solid grey;
  width: 150px;
  flex-direction: column;
  display: flex;
  padding:10;
}

.custom-dropdown-container > li {
  margin: 0;

  background-color: white;
}

.custom-dropdown-container > li:hover {
  background-color: lightgray;
}

.custom-dropdown-container > li > button {
  width: 100%;
  height: 100%;
  text-align: left;
  background: none;
  color: inherit;
  border: none;
  margin: 0;
  font: inherit;
  cursor: pointer;
  z-index: 10;
}

.custom-dropdown-container .dropdown-item{
  display:flex;
  align-items: center;
  cursor: pointer;
  padding-top: 2px;
  padding-bottom:2px;
}

.custom-dropdown-container .dropdown-item:hover{
  background-color: #f5f5f5;
}

#editorWrapper ol{
  list-style: decimal;
}

.fast-transition{
  transition:all .1s ease-in-out;
}

.max-w-activity{
  max-width: 1140px !important;
}

.slider-thumb{
  top: 1px;
  width: 35px;
  height: 33px;
  line-height: 38px;
  background-color: #22bafb;
  display: flex;
  justify-content:center;
  align-items:center;
  color:white;
  border-radius:999px;
  cursor: pointer;
  right: 27px;
}

.slider-track{
  width: 15px;
  background: #22bafb;
  border-radius:999px;
}

.slider-track-1{
  opacity: .3;
}

.vertical-slider{
  height: 400px;
  width: 50px;
}

.slider-mark{
  width: 15px;
  height:15px;
  background-color:white;
  border-radius:999px;
  overflow:hidden;
  cursor:pointer;
  display:inline-block;
  margin-top: 9px;
}

.show-bounce{
  transition:'all .3s ease-in-out';
  opacity:1;
}

model-viewer{
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.act-instructions{
  text-align:justify;
  font-weight:bold;
  color: #5f657e;
  padding-top: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-wrap;
}

.block-instructions{
  text-align:justify;
  padding-top: 1rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-wrap;
}

@media(max-width:1100px){
  .hotspot-creator{
    display: none;
  }
}

@media(min-width:1101px){
  .hotspot-creator-warning{
    display: none;
  }
}


.shadowed-table{
  width:100%;
  border-collapse:separate; 
  border-spacing: 0 1em;
}
.shadowed-table tbody tr
{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
}

.shadowed-table th,
.shadowed-table td{
  padding:1rem;
  text-align: center;
}

.shadowed-table td{
  color: #1a3d82;
}

.shadowed-table th{
  font-weight: normal !important;
}

.shadowed-table th:first-child,
.shadowed-table td:first-child{
  text-align: left ;
}

.bordered-table{
  width:100%;
  border-collapse:separate; 
  border-spacing: 0 .35em;
}
.bordered-table tbody tr
{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 8px;
}

.bordered-table th,
.bordered-table td{
  padding:1rem;
  text-align: center;
}

.bordered-table th{
  color: #000;
}

.min-h-screen-minus-h-8 {
  min-height: calc(100vh - 4rem);
}

.dashboard-box{
  background-color: white;
  padding: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
}

.dashboard-box-title{
  color: #1a3d82;
  margin-bottom: 1rem;
}

.cursor-box {
  width: 30em;
  height: 30em;
  position: absolute;
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.9);
  z-index: 10;
  border-radius: 50%;
  pointer-events: none;
  z-index: 999999999999999;
}

.progress-bar::-webkit-slider-thumb {
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #01c3ff;
  cursor: pointer;
}

.triangular-mask {
  position: relative;
  -webkit-clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
}
.triangular-mask img {
  width: 100%;
  height: 100%;
}
.cabecera-recortada-img {
  border-radius: 0 9px 9px 0;
}


